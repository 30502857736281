<template>
  <el-dialog
      title="更换设备sn号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      width="380px">
    <el-form label-position="left" class="common-form" label-width="110px"
             :model="form" :rules="rules" ref="form">
      <el-form-item label="设备sn号：" prop="imei">
        <el-input class="small-input" v-model="form.imei" placeholder="请输入设备sn号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="closeModal">取 消</el-button>
    <el-button size="small" type="primary" :loading="loading" @click="submit">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "UpdateImei",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    editInfo:{
      type:Object,
      default() {
        return null;
      }
    }
  },
  watch:{
    dialogVisible(val){
      if(val && this.editInfo){
        this.form.imei = this.editInfo.imei;
      }
    }
  },
  data() {
    return {
      form: {
        imei: ''
      },
      rules: {
        imei: [
          {required: true, message: '请输入imei', trigger: 'blur'},
        ]
      },
      loading:false
    }
  },
  methods: {
    handleClose() {
      this.closeModal()
    },
    closeModal(isRe){
      this.$emit("close", false,isRe);
    },
    submit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios.post("/sys-api/device-api/updateSn",{
            id:this.editInfo.id,
            imei:this.form.imei
          },(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("更换设备sn号成功");
              this.closeModal(true);
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
