<template>
  <div>
    <div class="table-page-title">
      <div>
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-col>
            <el-form-item label="" prop="departmentId">
              <el-select class="small-input" @change="handleChangeDepartment" v-model="searchForm.departmentId"
                         placeholder="请选择医院">
                <el-option
                        v-for="item in departmentList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="building">
              <el-select clearable class="small-input" v-model="searchForm.building" placeholder="请选择楼宇" @change="changeBuilding">
                <el-option
                        v-for="item in buildingList"
                        :key="item.building"
                        :label="item.building"
                        :value="item.building">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="floor">
              <el-select clearable class="small-input" v-model="searchForm.floor" placeholder="请选择楼层" @change="changeFloor">
                <el-option
                        v-for="item in floorList"
                        :key="item.floor"
                        :label="item.floor"
                        :value="item.floor">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="place">
              <el-select clearable class="small-input" v-model="searchForm.place" placeholder="请选择位置" @change="changePlace">
                <el-option
                        v-for="item in placeList"
                        :key="item.place"
                        :label="item.place"
                        :value="item.place">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item label="" prop="imei">
            <el-input class="small-input" v-model="searchForm.imei" placeholder="设备imei"></el-input>
          </el-form-item>
          <el-form-item label="" prop="bindingStatus">
            <el-select clearable class="small-input" v-model="searchForm.bindingStatus" placeholder="绑定状态">
              <el-option
                      v-for="item in bindingStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="" prop="initializeStatus">
            <el-select clearable class="small-input" v-model="searchForm.initializeStatus" placeholder="初始化状态">
              <el-option
                  v-for="item in initializeStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="search-form-btn">
        <el-button size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button size="small" type="primary" @click="reset()">重 置</el-button>
        <el-button size="small" type="primary" :loading="uploadLoading1" style="margin-right: 10px" @click="() => {$refs.upload_file1.click()}">导入</el-button>
        <input class="upload_file" type="file" ref="upload_file1" accept=".csv"  @change="uploadRatastool"/>
        <el-button size="small" type="primary" @click="downloadTem()">下载导入模板</el-button>
      </div>
      <!--<div class="ft" style="position: relative">
        <el-button size="small" type="primary" :loading="uploadLoading1" style="margin-right: 10px" @click="() => {$refs.upload_file1.click()}">导入轮椅</el-button>
        <input class="upload_file" type="file" ref="upload_file1" accept=".csv"  @change="uploadRatastool"/>
      </div>-->
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="设备id"
            align="center"
            header-align="center"
            width="200"
            :formatter="commonFormatter"
            prop="id">
        </el-table-column>
        <el-table-column
            label="设备imei"
            align="center"
            header-align="center"
            width="180"
            :formatter="commonFormatter"
            prop="imei">
        </el-table-column>
        <el-table-column
            label="初始化状态"
            prop="initializeStatus"
            align="center"
            width="100"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.initializeStatus === 0 ? '未初始化' : row.initializeStatus === 1 ? '已初始化' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
                label="医院"
                prop="department"
                align="center"
                header-align="center"
                show-overflow-tooltip="true"
                width="200"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="楼层位置"
                prop="position"
                align="center"
                header-align="center"
                show-overflow-tooltip="true"
                width="150"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
                label="绑定时间"
                prop="bindingTime"
                align="center"
                header-align="center"
                width="180"
                :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="添加时间"
            prop="createTime"
            :formatter="commonFormatter"
            align="center"
            width="180"
            header-align="center"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            width="420"
            fixed="right"
            label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="updateSN(row)">更换设备IMEI号</el-button>
            <el-button type="text" :disabled="!row.imei" v-clipboard:copy="row.imei" v-clipboard:success="copySuccess"
                       v-clipboard:error="copyError">复制设备IMEI
            </el-button>
            <el-button type="text" :disabled="!row.id" v-clipboard:copy="row.id" v-clipboard:success="copySuccess"
                       v-clipboard:error="copyError">复制设备ID
            </el-button>
            <el-button type="text" :disabled="row.bindingStatus !== 1 || row.initializeStatus !== 0" @click="goInitPage(row.deviceType,row.id)">初始化</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <UpdateImei :dialogVisible="updateImeiDialogVisible" :editInfo="editInfo" @close="handleUpdateImei"></UpdateImei>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {setMenuList} from "@/assets/js/common";
import UpdateImei from "@/views/deviceBinding/UpdateImei";

export default {
  name: "DeviceBindingList",
  mixins: [tableUse],
  components:{
    UpdateImei
  },
  data() {
    return {
      updateImeiDialogVisible:false,
      editInfo:null,
      searchForm: {
        imei: '',
        bindingStatus: '',
        initializeStatus: '',
        deviceType:'',
        departmentId: null,
        building: '',
        floor: '',
        place: '',
        positionId:''
      },
      tableData: [],
      bindingStatusList: [
        {
          value: 0,
          label: '未绑定'
        },
        {
          value: 1,
          label: '已绑定'
        }
      ],
      initializeStatusList: [
        {
          value: 0,
          label: '未初始化'
        },
        {
          value: 1,
          label: '已初始化'
        }
      ],
      deviceTypeList: [
        {
          value: 0,
          label: '陪护床'
        },
        {
          value: 1,
          label: '轮椅'
        }
      ],
      uploadLoading:false,
      uploadLoading1:false,
      departmentList:[],
      buildingList:[],
      floorList:[],
      placeList:[],
      positionList:[]
    }
  },
  methods: {
    reset() {
      this.searchForm = {
        imei: '',
        bindingStatus: '',
        initializeStatus: ''
      }
      this.search();
    },
    goInitPage(deviceType,id) {
      setMenuList({
        path: '/WheelinitDevice',
        name: '初始化设备'
      })
      this.$router.push({
        name: 'ChairInitDevice',
        query:{
          deviceType:deviceType.toString(),
          id:id
        }
      })
    },
    copySuccess() {
      this.$message.success("复制成功");
    },
    copyError() {
      this.$message.error("复制失败");
    },
    updateBindingCode(id) {
      this.$axios.post("/sys-api/device-api/updateBindingCode", {id}, (res) => {
        if (res.code === '100') {
          this.$message.success("更新绑定码成功");
          this.getTableData();
        }
      })
    },
    updateSN(row){
     this.editInfo = row;
     this.handleUpdateImei(true)
    },
    handleUpdateImei(visible, isRe){
      this.updateImeiDialogVisible = visible;
      if (!visible) {
        this.editInfo = null;
      }
      if (isRe) {
        this.getTableData();
      }
    },
    uploadFile(){
      this.uploadLoading = true;
      let file = this.$refs.upload_file.files[0];
      let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (extName.indexOf('csv') === -1) {
        this.$message.error("只能上传csv文件");
        return
      }
      let formData = new FormData();
      formData.append("file", file);
      this.$axios.form("/sys-api/device-api/deviceFile", formData, (res) => {
        this.uploadLoading = false;
        if (res.code === '100') {
          this.$message.success("导入设备成功");
          this.getTableData();
        }
      })
    },
    uploadRatastool(){
      this.uploadLoading1 = true;
      let file = this.$refs.upload_file1.files[0];
      let extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (extName.indexOf('csv') === -1) {
        this.$message.error("只能上传csv文件");
        return
      }
      let formData = new FormData();
      formData.append("file", file);
      this.$axios.form("/sys-api/device-api/deviceRatastool", formData, (res) => {
        this.uploadLoading1 = false;
        if (res.code === '100') {
          this.$message.success("导入设备成功");
          this.getTableData();
        }
      })
    },
    getTableData() {
      this.tableLoading = true;
      this.searchForm.pageNum = this.pageNum;
      this.searchForm.pageSize = this.pageSize;
      /*if (this.searchForm.imei) formData.imei = this.searchForm.imei;
      if (this.searchForm.bindingStatus || this.searchForm.bindingStatus === 0) formData.bindingStatus = this.searchForm.bindingStatus;
      if (this.searchForm.initializeStatus || this.searchForm.initializeStatus === 0) formData.initializeStatus = this.searchForm.initializeStatus;*/
      this.searchForm.deviceType = 1;
      this.$axios.post("/sys-api/device-api/getDevicePage", this.searchForm, (res) => {
        this.tableLoading = false;
        if (res.code === '100') {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    },
    getDepartmentList(){
      this.$axios.get("/sys-api/site-api/getDepartment",{},(res) => {
        if(res.code === '100'){
          this.departmentList = res.data;
        }
      })
    },
    handleChangeDepartment(){
      this.searchForm.positionId = '';
      this.searchForm.building = "";
      this.searchForm.floor = "";
      this.searchForm.place = "";
      this.getPositionList();
    },
    getPositionList(){
      this.$axios.get("/sys-api/site-api/getPosition",{
        departmentId:this.searchForm.departmentId
      },(res) => {
        if(res.code === '100'){
          this.getBuildingList(res.data || []);
          //this.getFloorList(res.data || []);
          //this.getPlaceList(res.data || []);
          this.positionList = res.data || [];
        }
      })
    },
    changeBuilding(){
      this.searchForm.floor = "";
      this.searchForm.place = "";
      this.getFloorList(this.positionList);
    },
    changeFloor(){
      this.searchForm.place = "";
      this.getPlaceList(this.positionList);
    },
    changePlace(){
    },
    getBuildingList(arr){
      this.buildingList = [];
      arr.forEach((item) => {
        if(this.buildingList.every((exist) => {return item.building !== exist.building})){
          this.buildingList.push(item);
        }
      })
    },
    getFloorList(arr){
      this.floorList = [];
      if(this.searchForm.building) arr = arr.filter((item) => {return item.building === this.searchForm.building});
      arr.forEach((item) => {
        if(this.floorList.every((exist) => {return item.floor !== exist.floor})){
          this.floorList.push(item);
        }
      })
    },
    getPlaceList(arr){
      this.placeList = [];
      if(this.searchForm.building) arr = arr.filter((item) => {return item.building === this.searchForm.building});
      if(this.searchForm.floor) arr = arr.filter((item) => {return item.floor === this.searchForm.floor});
      arr.forEach((item) => {
        if(this.placeList.every((exist) => {return item.place !== exist.place})){
          this.placeList.push(item);
        }
      })
    },
    downloadTem(){
      window.location.href = '/static/template.csv';
    }
  },
  mounted() {
    this.getTableData();
    this.getDepartmentList();
  }
}
</script>

<style scoped lang="scss">
.upload_file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
.table-container {
  height: calc(100% - 155px);
}
</style>
